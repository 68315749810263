import {api, SHARE_LINK_API_V1, DEAL_ROOM_ANALYTICS_API} from '../api';
import {AxiosResponse} from 'axios';
import {IDealRoomAnalyticsResponse, IShareLinkPagination} from 'spekit-types';
import {stringifyQs} from '../utils/commonUtils';

import {IDealRoomBundle, IDealRoomBundleUpdateData} from 'spekit-types';

/**
 * Retrieves a specific Bundle by its ID
 * @param bundleId The ID of the Bundle to retrieve
 * @returns Promise with the Bundle data
 */
export const getBundle = (bundleId: string) => {
  return api
    .get<IDealRoomBundle>(`${SHARE_LINK_API_V1}${bundleId}/`)
    .then((res: AxiosResponse<IDealRoomBundle>) => res.data);
};

/**
 * Updates a specific Bundle with partial data
 * @param bundleId The ID of the Bundle to update
 * @param data The data to update the Bundle with
 * @returns Promise with the updated Bundle data
 */
export const updateBundle = (bundleId: string, data: IDealRoomBundleUpdateData) => {
  return api
    .patch<IDealRoomBundle>(`${SHARE_LINK_API_V1}${bundleId}/`, data)
    .then((res: AxiosResponse<IDealRoomBundle>) => res.data);
};

export const getDealRoomsAnalytics = (pagination: IShareLinkPagination) => {
  const query = stringifyQs(pagination, {
    addQueryPrefix: true,
  });
  return api
    .get<IDealRoomAnalyticsResponse>(`${DEAL_ROOM_ANALYTICS_API}${query}`)
    .then((res: AxiosResponse<IDealRoomAnalyticsResponse>) => res.data);
};
