import {lazy, Suspense} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {IFlagState} from 'spekit-types';
import {useSelector} from 'react-redux';

const DealRoomPage = lazy(() => import('./pages/DealRoomDetails/DealRoom'));

interface IState {
  flag: IFlagState;
}

const DealRoomRoute = () => {
  const flags = useSelector((state: IState) => state.flag);
  const {hasDealRoomFlag} = flags;

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route path='/app/deal-room/:dealRoomId'>
          {hasDealRoomFlag ? <DealRoomPage /> : <Redirect to='/app/wiki/topics' />}
        </Route>
      </Switch>
    </Suspense>
  );
};

export default DealRoomRoute;
